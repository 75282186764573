import { categories } from "../locales/en";
import { categoriesZh } from "../locales/zh";

const specialCases = {
  'Renovations': {
    'Wall paper': 'Wall paper',
    'Damp proofing': 'Damp proofing',
  },
  'Painting': {
    'Wall paper': 'Wall paper 2',
  },
  'Landscapes': {
    'Damp proofing': 'Damp proofing 2',
  }
}

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

// Return the corresponding category ID by giving a translation or
const getCategoryKey = (categoryName, parentCategory) => {
  // Handle special cases that some categories like Renovation has duplicated name
  if (parentCategory && parentCategory in specialCases) {
    if (categoryName in specialCases[parentCategory]) {
      return specialCases[parentCategory][categoryName];
    }
  }

  const enKey = getKeyByValue(categories, categoryName);
  const zhKey = getKeyByValue(categoriesZh, categoryName);

  // Return whatever is found, only one will be available at a time, 
  // even if both variables has values, they should have the same key
  return enKey || zhKey || "";
};

/**
 * Convert a category to corresponding slug in wordpress
 * 
 * @param {string} categoryName 
 * @returns {string} The corresponding slug for the category in worpdress
 * 
 * @example 
 * // returns 'concrete-tiles'
 * toWpCategorySlug('Concrete & Tiles')
 */
export const toWpCategorySlug = (categoryName, keyPath) => {
  let parentCategory = null;
  if (keyPath && Array.isArray(keyPath)) {
    parentCategory = keyPath[0];
  }
  
  if (categoryName.toLowerCase() === "wall paper 2" || categoryName.toLowerCase() === 'damp proofing 2') {
    // Append the parent name after the categor name follow by Wordpress's rule
    // e.g. Renovation -> Wall paper, slug: wall-paper-renovation
    if (parentCategory && parentCategory !== "Renovation") {
      return `${categoryName} ${parentCategory}`.replace(/\W+/g, '-').toLowerCase();
    }
  }
  return categoryName.replace(/\W+/g, '-').toLowerCase();
}

export default getCategoryKey;
