const defaultQuestions = [
  '1) Do you have materials?',
  '2) What are dimensions? (e.g. height, width, depth)',
  '3) Additional descriptions',
]

/**
 * 
 * @param {string[]} details List of detail paragraphs to display
 * @returns Combined paragraphs
 */
export default function generateDescription(details, questions = defaultQuestions) {
  let result = ''
  for (var i = 0; i < details.length; i++) {
    if (questions[i]) {
      result += `<p>${questions[i]}</p><p><strong>${details[i]}</strong></p>`
    } else {
      result += `<p><strong>${details[i]}</strong><p>`
    }
    // result += '<br/>'
  }
  return result
}
