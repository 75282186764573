// Categories
export const categories = {
  "Handyman": "Handyman",
  "Install & Replace shelves": "Install & Replace shelves",
  "Repair windows & doors": "Repair windows & doors",
  "Repair & replace handle rails": "Repair & replace handle rails",
  "Cleaning gutter": "Cleaning gutter",
  "Patched wall": "Patched wall",
  "Hanging pictures & mirrors": "Hanging pictures & mirrors",
  "Assembly furniture": "Assembly furniture",
  "Any another odd jobs": "Any another odd jobs",
  "Electricians": "Electricians",
  "New electrical installations": "New electrical installations",
  "Renovation electricians": "Renovation electricians",
  "Lights installations": "Lights installations",
  "Wires": "Wires",
  "Electrical repairs": "Electrical repairs",
  "Electrical power points": "Electrical power points",
  "Solar power": "Solar power",
  "Electrical data cabling": "Electrical data cabling",
  "Plumber": "Plumber",
  "New home plumbers": "New home plumbers",
  "Plumber maintenance": "Plumber maintenance",
  "Blocked drains": "Blocked drains",
  "Toilet repairs": "Toilet repairs",
  "Bathroom installations": "Bathroom installations",
  "Drains": "Drains",
  "Builders": "Builders",
  "Architects": "Architects",
  "Town planning": "Town planning",
  "Drafting": "Drafting",
  "Building design & consultants": "Building design & consultants",
  "Building surveyors": "Building surveyors",
  "Building inspections": "Building inspections",
  "Building supplies": "Building supplies",
  "Sueveyor": "Sueveyor",
  "Structural engineer": "Structural engineer",
  "Asphalt concreting": "Asphalt concreting",
  "Brick layers": "Brick layers",
  "Garages": "Garages",
  "Concrete kerbs & resurfacing": "Concrete kerbs & resurfacing",
  "Excavation": "Excavation",
  "Plasterings": "Plasterings",
  "Skylights": "Skylights",
  "Solar panel": "Solar panel",
  "Staircase": "Staircase",
  "Stonemasons": "Stonemasons",
  "Verandahs": "Verandahs",
  "Fire place": "Fire place",
  "Renovations": "Renovations",
  "Interior design": "Interior design",
  "Demolition": "Demolition",
  "Bathroom renovation": "Bathroom renovation",
  "Kitchen renovation": "Kitchen renovation",
  "Doors & Windows ": "Doors & Windows ",
  "Carpets": "Carpets",
  "Floors": "Floors",
  "Wall paper": "Wall paper",
  "Wall paper 2": "Wall paper",
  "Ceilings": "Ceilings",
  "Cladding": "Cladding",
  "Cabinets": "Cabinets",
  "Curtains & blind": "Curtains & blind",
  "Water proofing ": "Water proofing ",
  "Damp proofing": "Damp proofing",
  "Damp proofing 2": "Damp proofing",
  "Roof repair": "Roof repair",
  "Security screen doors": "Security screen doors",
  "Roller doors": "Roller doors",
  "Appliances installation & repairs": "Appliances installation & repairs",
  "All appliances installation & repairs": "All appliances installation & repairs",
  "Equipment hire": "Equipment hire",
  "Landscapes": "Landscapes",
  "Landscape architecture": "Landscape architecture",
  "Garden maintenance": "Garden maintenance",
  "Lawn mowing": "Lawn mowing",
  "Awning suppliers": "Awning suppliers",
  "Balustrades": "Balustrades",
  "Carport": "Carport",
  "Decking": "Decking",
  "Fencing": "Fencing",
  "Fly screen": "Fly screen",
  "Gates": "Gates",
  "Gazebo": "Gazebo",
  "Gutter": "Gutter",
  "Handrails": "Handrails",
  "Irrigation systems": "Irrigation systems",
  "Patio": "Patio",
  "Pergolas": "Pergolas",
  "Shade": "Shade",
  "Storage": "Storage",
  "Tree fellers": "Tree fellers",
  "Render": "Render",
  "Retaining wall": "Retaining wall",
  "Water features": "Water features",
  "Rainwater tanks": "Rainwater tanks",
  "Pest control": "Pest control",
  "Painting": "Painting",
  "Wall & ceiling paint": "Wall & ceiling paint",
  "Roof paint": "Roof paint",
  "Locksmith": "Locksmith",
  "New lock installation": "New lock installation",
  "Replace & Repair locks": "Replace & Repair locks",
  "Cleaning & Removalist": "Cleaning & Removalist",
  "House cleaning": "House cleaning",
  "Airbnb property cleaning": "Airbnb property cleaning",
  "Roof cleaning": "Roof cleaning",
  "Window cleaning": "Window cleaning",
  "Pressure cleaner": "Pressure cleaner",
  "Gutter cleaning & protection": "Gutter cleaning & protection",
  "Rubbish removal": "Rubbish removal",
  "Hire truck": "Hire truck",
  "Swimming Pool": "Swimming Pool",
  "Pool design": "Pool design",
  "Pool builder": "Pool builder",
  "Pool fencing": "Pool fencing",
  "Pool heating": "Pool heating",
  "Pool maintenance": "Pool maintenance",
  "Concrete & tiles": "Concrete & tiles",
  "Concrete kerbs": "Concrete kerbs",
  "Concrete resurfacing": "Concrete resurfacing",
  "Pavers": "Pavers",
  "Bathroom tiling": "Bathroom tiling",
  "Floor tiling": "Floor tiling",
  "Splashbacks": "Splashbacks",
  "Smart house": "Smart house",
  "Antenna services": "Antenna services",
  "Security & camaras systom installations & repairs": "Security & camaras systom installations & repairs",
  "Smart home systoms installations": "Smart home systoms installations",
}

const en = {
  "What do you want to be done": "What do you want to be done",
  "Pick location and time": "Pick location and time",
  "Upload attachments": "Upload attachments",
  "e.g. I need to assemble my sofa": "e.g. I need to assemble my sofa",
  "Postcode": "Postcode",
  "Address": "Address",
  "Post": "Post",
  "What do you want to be done? (Job Title)": "What do you want to be done? (Job Title)",
  "e.g. I want someone to help me to assemble furniture": "e.g. I want someone to help me to assemble furniture",
  "Which category does it belong to?": "Which category does it belong to?",
  "Please enter a job title": "Please enter a job title",
  "Invalid postcode format": "Invalid postcode format",
  "optional": "optional",
  "What are the details?": "What are the details?",
  "detailDescriptions": "e.g. The decking need finished with gloss painted.",
  "detailDescriptions1": "e.g. I have timbers for the decking.",
  "detailDescriptions2": "e.g.  The decking area is around 4m width; 5m Length;1.2m height.",
  "AM": "AM",
  "PM": "PM",
  "Where do you need this job to be done? (Job Location)": "Where do you need this job to be done? (Job Location)",
  "When do you need it done? (Optional)": "When do you need it done? (Optional)",
  "When do you need it done?": "When do you need it done?",
  "Timeline": "Timeline",
  "It is urgent": "It is an urgent job",
  "You can choose to upload pictures or a video": "You can choose to upload pictures or a video",
  "Video": "Video",
  "Pictures": "Pictures",
  "Drag & drop files here or upload a file by click": "Drag & drop files here or upload a file by click",
  "Maximum 9 pictures": "Maximum: 9 pictures",
  "Maximum 10MB": "Maximum: 10MB",
  "Video must smaller than 10MB!": "Video must smaller than 10MB!",
  "Next": "Next",
  "Welcome to Mijobs!": "Welcome to Mijobs!",
  "You will get 10 reward points for signing up.": "You will get 10 reward points for signing up.",
  "Congratulations! The job has been posted successfully!": "Congratulations! The job has been posted successfully!",
  "We will email you when the most suitable tradies submit their quotations.": "We will email you when the most suitable tradies submit their quotations.",
  "Close": "Close",
  "Please upload at least one picture":"Please upload at least one picture.",
  "For posting your jobs, please Login or register your Mijobs account first.": "For posting your jobs, please login or register your Mijobs account first.",
  "Submit": "Submit",
  "Login": "Login",
  "Register": "Register",
  "notEmployerCannotPostJob": "Sorry, tradies are not allowed to post jobs",
  "Please wait...": "Please wait...",
  "(More detailed description can help you get more accurate quotations)": "(More detailed description can help you get more accurate quotations)",
  "Please enter job details": "Please enter job details",
  "Please enter a postcode": "Please enter a postcode",
  "Please enter job address": "Please enter job address",
  "Please enter job date": "Please enter job date",
  "Please enter job timeline": "Please enter job timeline",
  "1) Do you have materials?": "1) Do you have materials?",
  "2) What are dimensions? (e.g. height, width, depth)": "2) What are dimensions? (e.g. height, width, depth)",
  "3) Additional descriptions": "3) Additional descriptions",
  "Job Details": "Job Details",

  ...categories,
}

export default en;