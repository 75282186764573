const localBaseURL =
  window.location.origin +
  window.location.pathname.split('/').slice(0, 2).join('/')
const remoteBaseURL = ''

export const baseURL = (() => {
  if (window.location.hostname.includes('local')) {
    // Can we get the site URL from wordpress's global variable
    if (window.scripts_vars && window.scripts_vars.site_url) {
      // just use the URL given by worpdress instead
      return window.scripts_vars.site_url
    }
    return localBaseURL
  }
  return remoteBaseURL
})()
export const googleApikey = 'AIzaSyCVkeFktfutCBGeFevRY26a6Z1XbF2SLYs'
