
// Categories
export const categoriesZh = {
  "Handyman": "勤杂工",
  "Install & Replace shelves": "安装和更换架子",
  "Repair windows & doors": "维修门窗",
  "Repair & replace handle rails": "修理和更换扶手",
  "Cleaning gutter": "清理水沟",
  "Patched wall": "补墙",
  "Hanging pictures & mirrors": "悬挂图片和镜子",
  "Assembly furniture": "装配家具",
  "Any another odd jobs": "任何其他零星的工作",
  "Electricians": "电工维修",
  "New electrical installations": "新电气安装",
  "Renovation electricians": "装修电工",
  "Lights installations": "灯具安装",
  "Wires": "电线",
  "Electrical repairs": "电气维修",
  "Electrical power points": "电源点",
  "Solar power": "太阳能发电",
  "Electrical data cabling": "电气数据布线",
  "Plumber": "管道工",
  "New home plumbers": "新房水管工",
  "Plumber maintenance": "水管工维修",
  "Blocked drains": "堵塞的下水道",
  "Toilet repairs": "厕所维修",
  "Bathroom installations": "卫生间安装",
  "Drains": "排水口",
  "Builders": "建筑工",
  "Architects": "建筑师",
  "Town planning": "城市规划",
  "Drafting": "绘图",
  "Building design & consultants": "建筑设计与咨询",
  "Building surveyors": "建筑测量师",
  "Building inspections": "建筑检查",
  "Building supplies": "建筑用品",
  "Sueveyor": "搬运工人",
  "Structural engineer": "结构工程师",
  "Asphalt concreting": "沥青混凝土施工",
  "Brick layers": "铺砖",
  "Garages": "车库",
  "Concrete kerbs & resurfacing": "混凝土路缘石和重铺路面",
  "Excavation": "挖掘工程",
  "Plasterings": "抹灰层",
  "Skylights": "天窗",
  "Solar panel": "太阳能板",
  "Staircase": "楼梯",
  "Stonemasons": "石匠",
  "Verandahs": "阳台",
  "Fire place": "火场",
  "Renovations": "装修翻修",
  "Interior design": "室内设计",
  "Demolition": "拆迁",
  "Bathroom renovation": "卫生间装修",
  "Kitchen renovation": "厨房装修",
  "Doors & Windows ": "门和窗 ",
  "Carpets": "地毯",
  "Floors": "地板",
  "Wall paper": "墙纸",
  "Wall paper 2": "墙纸",
  "Ceilings": "天花板",
  "Cladding": "墙板",
  "Cabinets": "橱柜",
  "Curtains & blind": "窗帘和百叶窗",
  "Water proofing ": "防水 ",
  "Damp proofing": "防潮处理",
  "Damp proofing 2": "防潮",
  "Roof repair": "屋顶维修",
  "Security screen doors": "安全纱门",
  "Roller doors": "卷帘门",
  "Appliances installation & repairs": "电器安装和维修",
  "All appliances installation & repairs": "所有电器的安装和维修",
  "Equipment hire": "设备租赁",
  "Landscapes": "景观设计",
  "Landscape architecture": "景观设计",
  "Garden maintenance": "花园维护",
  "Lawn mowing": "草坪修剪",
  "Awning suppliers": "遮阳篷供应商",
  "Balustrades": "栏杆",
  "Carport": "车棚",
  "Decking": "木板",
  "Fencing": "栅栏",
  "Fly screen": "防蝇网",
  "Gates": "大门",
  "Gazebo": "凉亭",
  "Gutter": "天沟",
  "Handrails": "扶手栏杆",
  "Irrigation systems": "灌溉系统",
  "Patio": "天井",
  "Pergolas": "凉亭",
  "Shade": "遮阳",
  "Storage": "储藏室",
  "Tree fellers": "伐木工人",
  "Render": "泥工",
  "Retaining wall": "挡土墙",
  "Water features": "水景",
  "Rainwater tanks": "雨水箱",
  "Pest Control": "虫害控制",
  "Painting": "油漆粉刷",
  "Wall & ceiling paint": "墙面和天花板涂料",
  "Roof paint": "屋顶漆",
  "Locksmith": "锁匠服务",
  "New lock installation": "安装新锁",
  "Replace & Repair locks": "更换和维修锁具",
  "Cleaning & Removalist": "清洁和搬运",
  "House cleaning": "房屋清洁",
  "Airbnb property cleaning": "民宿旅馆物业清洁",
  "Roof cleaning": "屋顶清洁",
  "Window cleaning": "窗户清洁",
  "Pressure cleaner": "压力清洗",
  "Gutter cleaning & protection": "沟渠清洁和保护",
  "Rubbish removal": "垃圾清理",
  "Hire truck": "租用卡车",
  "Swimming Pool": "游泳池",
  "Pool design": "泳池设计",
  "Pool builder": "泳池建设者",
  "Pool fencing": "泳池围栏",
  "Pool heating": "泳池加热",
  "Pool maintenance": "泳池维护",
  "Concrete & tiles": "混凝土&瓷砖",
  "Concrete kerbs": "混凝土路缘石",
  "Concrete resurfacing": "混凝土重新铺设",
  "Pavers": "铺面",
  "Bathroom tiling": "卫生间瓷砖",
  "Floor tiling": "地板贴砖",
  "Splashbacks": "挡板",
  "Smart house": "智能家装",
  "Antenna services": "天线服务",
  "Security & camaras systom installations & repairs": "安全和凸轮轴系统的安装和维修",
  "Smart home systoms installations": "智能家居系统安装",
  "Carpenter": "木匠",
  "House maintenance": "房屋维护",
}

const zh = {
  "What do you want to be done": "填写您的需求",
  "Pick location and time": "请输入地址和所需的时间",
  "Upload attachments": "上传图片或者附件",
  "e.g. I need to assemble my sofa": "例如：更换水龙头",
  "Postcode": "邮政编码",
  "Address": "地址",
  "Post": "发布",
  "What do you want to be done? (Job Title)": "填写您的需求",
  "e.g. I want someone to help me to assemble furniture": "例如：需要帮助安装家具",
  "Which category does it belong to?": "种类",
  "Please enter a job title": "请输入您的需求",
  "Invalid postcode format": "邮政编码格式错误",
  "optional": "（选填）",
  "What are the details?": "详情描述",
  "detailDescriptions": "e.g. The decking need finished with gloss painted.",
  "detailDescriptions1": "e.g. I have timbers for the decking.",
  "detailDescriptions2": "e.g. The decking area is around 4m width; 5m length; 1.2m height.",
  "AM": "AM",
  "PM": "PM",
  "Where do you need this job to be done? (Job Location)": "所需服务的地址（请详细填写 门牌号，街道，所在区，州，邮编）",
  "When do you need it done? (Optional)": "你大约需要什么时候开始工作（选填）",
  "When do you need it done?": "你大约需要什么时候开始工作",
  "Timeline": "时间",
  "It is urgent": "是否急需",
  "You can choose to upload pictures or a video": "您可以选择上传图片或者视频（尽量详细的图片和视频可有助于服务商报价）",
  "Video": "视频",
  "Pictures": "图片",
  "Drag & drop files here or upload a file by click": "拖拽文件到此处或者点击加号上传文件",
  "Maximum 9 pictures": "最多9张图片",
  "Maximum 10MB": "文件大小：10MB 以下",
  "Video must smaller than 10MB!": "视频大小需小于10MB!",
  "Next": "下一步",
  "Welcome to Mijobs!": "Mijobs欢迎您！",
  "You will get 10 reward points for signing up.": "注册mijobs, 您将获得10个积分",
  "Congratulations! The job has been posted successfully!": "恭喜您，已成功发布！",
  "We will email you when the most suitable tradies submit their quotations.": "一旦服务商给您报价，我们会立刻发邮件通知您，请注意查收",
  "Close": "关闭",
  "Please upload at least one picture":"请上传至少一张图片",
  "For posting your jobs, please Login or register your Mijobs account first.": "在发布前，请先登录或者注册您的Mijobs账号。",
  "Submit": "提交",
  "Login": "登录",
  "Register": "注册",
  "notEmployerCannotPostJob": "服务商无法创建工作",
  "Please wait...": "请稍候…",
  "(More detailed description can help you get more accurate quotations)": "（请尽可能详细地描述，有助于您得到更准确的报价）",
  "Please enter job details": "请输入详情描述",
  "Please enter a postcode": "请输入邮政编码",
  "Please enter job address": "请输入地址",
  "Please enter job date": "请选择日期",
  "Please enter job timeline": "请输入工作完成时间",
  "1) Do you have materials?": "1) 您是否已备材料",
  "2) What are dimensions? (e.g. height, width, depth)": "2) 请输入尺寸 (例如：长度、宽度、厚度)",
  "3) Additional descriptions": "3) 更多描述",
  "Job Details": "工作详情",

  ...categoriesZh,
}

export default zh;