import { LeftOutlined, CloseOutlined } from '@ant-design/icons'
import { Modal, Steps, Button, message } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Geocode from 'react-geocode'
import StepOne from './StepOne'
import StepJobDetail from './StepJobDetail'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import StepFour from './StepFour'
import { baseURL, googleApikey } from '../../config'
import {
  saveObjToStroage,
  clearSavedObj,
} from '../../utils/sessionStorageHelper'
import { toWpCategorySlug } from '../../utils/getCategoryKey'

import './css/steps.css'

axios.defaults.baseURL = baseURL

const { Step } = Steps

const titles = [
  'What do you want to be done',
  'Job Details',
  'Pick location and time',
  'Upload attachments',
]

const iconStyle = {
  color: '#666666',
  width: 16,
  height: 16,
}

const successStep = titles.length // 4

const PostJobModal = ({
  isModalVisible,
  setIsModalVisible,
  prefill,
  title,
  category,
  categoryKeyPath,
  details,
  details1,
  details2,
  details3,
  postcode,
  address,
  date,
  timeOne,
  timeTwo,
  isUrgent,
  geocodeResult: geocodeResultProp,
  setStateOne,
  setStateTwo,
  setIsModalTwoVisible,
  stepThreeState,
  setStepThree: setStepThreeState,
  resetState,
  step,
  setStepState,
  showInput,
}) => {
  // const [step, setStepState] = useState(stepProp)
  const [isUploading, setIsUploading] = useState(false)

  // const [stepThreeState, setStepThreeState] = useState({
  //   previewVisible: false,
  //   previewImage: '',
  //   previewTitle: '',
  //   imageFileList: [],
  //   videoFileList: [],
  //   videoUploading: false,
  // })

  const { t } = useTranslation()

  // Save the states before setting the step
  const setStep = (i) => {
    if (typeof i === 'number') {
      if (i < successStep) {
        saveObjToStroage({
          title,
          category,
          categoryKeyPath,
          details,
          details1,
          details2,
          details3,
          postcode,
          address,
          date,
          timeOne,
          timeTwo,
          isUrgent,
          stepThreeState,
          geocodeResult: geocodeResultProp,
          step, // record the step allow recover
        })
      } else {
        resetState()
        clearSavedObj()
      }
    }
    setStepState(i)
  }

  const handleClick = () => {
    console.log(
      title,
      category,
      details,
      postcode,
      address,
      date,
      timeOne,
      timeTwo
    )

    setStep(0)
    setIsModalVisible(false)
    clearSavedObj()
    window.location.reload()
  }

  const handleSubmit = async () => {
    if (isUploading) {
      return
    }
    setIsUploading(true)
    // Save the current progress in session storage
    saveObjToStroage({
      title,
      category,
      categoryKeyPath,
      details,
      postcode,
      address,
      date,
      timeOne,
      timeTwo,
      isUrgent,
      stepThreeState,
      geocodeResult: geocodeResultProp,
      step,
    })
    // Check User has logged in
    const isLogin = document.getElementById('checkLogin').onclick()
    console.log('clickpost', isLogin)

    if (!isLogin) {
      // Add a query parameter to URL to open the modal after page refresh
      let queryParams = new URLSearchParams(window.location.search)
      queryParams.set('showPopup', showInput ? 'homepage' : 'other')
      window.history.replaceState(null, null, '?' + queryParams.toString())

      setIsModalTwoVisible(true)
      setIsUploading(false)
      return
    }

    // Check user type is employer, not freelancer
    const scripts_vars = window.scripts_vars
    console.log(`scripts_vars`, scripts_vars)
    if (scripts_vars.user_type !== 'employer') {
      message.error(t('notEmployerCannotPostJob'))
      setIsUploading(false)
      return
    }

    let geocodeResult = null

    if (
      geocodeResultProp &&
      geocodeResultProp.value &&
      geocodeResultProp.value.place_id
    ) {
      const geocodeResponse = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?place_id=${geocodeResultProp.value.place_id}&key=${googleApikey}`
      )
      geocodeResult = geocodeResponse.data
    } else {
      try {
        geocodeResult = await Geocode.fromAddress(address)
        console.log(geocodeResult)
      } catch (error) {
        message.error(error.toString())
      }
    }

    let state = ''
    let suburb = ''

    // Melbourne CBD
    let lat = -37.8135231
    let lng = 144.9638563

    if (geocodeResult && geocodeResult.status === 'OK') {
      const address = geocodeResult.results[0]
      // get state and suburb component in address
      const addressState = address.address_components.find((item) => {
        return item.types.find((str) => str === 'administrative_area_level_1')
      })

      if (addressState && addressState.short_name) {
        state = addressState.short_name
      }

      const addressSuburb = address.address_components.find((item) => {
        return item.types.find((str) => str === 'locality')
      })

      if (addressSuburb && addressSuburb.short_name) {
        suburb = addressSuburb.short_name
      }

      // latitude and longitude

      if (address.geometry && address.geometry.location) {
        lat = address.geometry.location.lat
        lng = address.geometry.location.lng
      }
    }

    const { imageFileList, videoFileList } = stepThreeState

    const nonce = window.post_job_nonce ? window.post_job_nonce : ''
    console.log('keypath', categoryKeyPath)
    let categorySlug = toWpCategorySlug(category, categoryKeyPath)

    const formData = new FormData()
    formData.append('post_job', nonce)
    formData.append('action', 'workreap_post_job')
    formData.append('submit_type', 'add')
    formData.append('job[title]', title)
    formData.append('job[project_type]', 'fixed')
    formData.append('job[ampm]', timeOne)
    formData.append('job[time]', timeTwo)
    formData.append('job[categories][]', categorySlug)
    formData.append('job[postcode]', postcode)
    formData.append('job[latitude]', lat)
    formData.append('job[longitude]', lng)
    formData.append('job[postcode]', postcode)
    formData.append('job[is_featured]', isUrgent ? 'on' : 'off')
    formData.append('job[country]', state.toLowerCase())
    formData.append('job[state]', state)
    formData.append('job[suburb]', suburb)
    formData.append('job[address]', address)
    formData.append('job[description]', details)
    formData.append('job[show_attachments]', 'on')
    formData.append('job[deadline]', date)
    formData.append('job[expiry_date]', date)

    // Attach images
    imageFileList.forEach((item) => {
      // attach image
      formData.append('job[project_documents][]', item.response.thumbnail)
    })

    // Attach video
    if (videoFileList && videoFileList.length > 0) {
      const videoUrl = videoFileList[0].response.thumbnail
      // formData.append('job[project_documents][]', videoUrl)
      formData.append('job[video]', videoUrl)
    }

    await axios
      .post('/wp-admin/admin-ajax.php', formData, {})
      .then((response) => {
        const data = response.data

        if (response.status === 400) {
          message.error(response.statusText)
        }

        // console.log(data)
        if (data.type === 'success') {
          // Clear the stored data
          setIsUploading(false)
          clearSavedObj()
          resetState()
          setStep(successStep)
        } else {
          // format: { "type":"error","message": string }
          message.error(data.message)
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          message.error('Please first login')
        } else {
          message.error(error.toString())
        }
      })
    setIsUploading(false)
  }

  return (
    <Modal
      closable={false}
      onCancel={() => {
        setIsModalVisible(false)

        if (step >= successStep) {
          // When the modal has reached the last step
          clearSavedObj()
          resetState()

          // Clear the query param
          window.history.replaceState(null, null, '')
        }
        setStep(step)
      }}
      visible={isModalVisible}
      footer={null}
      title={null}
      width={900}
      bodyStyle={{
        borderRadius: 20,
        padding: step < successStep ? '10px 20px 10px 20px' : 0,
        minHeight: step < successStep ? (step === 3 ? 300 : 500) : 350,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {step < successStep && (
        <div
          style={{
            fontWeight: '500',
            fontSize: 20,
            marginBottom: 20,
            width: '100%',
            padding: '20px 20px 0 20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={() => step > 0 && setStep((step) => step - 1)}
            type="text"
          >
            {step > 0 && <LeftOutlined style={iconStyle} size={16} />}
          </Button>
          <div>{t(titles[step])}</div>
          <Button
            icon={<CloseOutlined style={iconStyle} size={16} />}
            onClick={() => setIsModalVisible(false)}
            type="text"
          ></Button>
        </div>
      )}
      {step < successStep && (
        <div className="post-job-form-steps-container">
          <Steps className="post-job-form-steps" progressDot current={step}>
            <Step />
            <Step />
            <Step />
            <Step />
          </Steps>
        </div>
      )}

      {step === 0 ? (
        <StepOne
          setStep={setStep}
          {...{ title, category, details }}
          setState={setStateOne}
        />
      ) : step === 1 ? ( // Add extra step
        <StepJobDetail
          setStep={setStep}
          setState={setStateTwo}
          {...{ details1, details2, details3 }}
        />
      ) : step === 2 ? (
        <StepTwo
          setStep={setStep}
          {...{
            postcode,
            address,
            date,
            timeOne,
            timeTwo,
            isUrgent,
            geocodeResult: geocodeResultProp,
          }}
          setState={setStateTwo}
        />
      ) : step === 3 ? (
        <StepThree
          setStep={setStep}
          onSubmit={handleSubmit}
          stepThreeState={stepThreeState}
          setState={setStepThreeState}
          isUploading={isUploading}
        />
      ) : (
        <StepFour handleClick={handleClick} />
      )}
    </Modal>
  )
}

export default PostJobModal

export const styles = {
  inputText: { color: '#000', fontSize: '18px' },
}
