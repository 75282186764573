import { Button, Image } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import background from '../../assets/background.png'
import ok from '../../assets/ok.png'

export default function StepFour({ handleClick }) {
  const { t } = useTranslation()
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
    >
      <Image
        src={ok}
        preview={false}
        style={{ width: 100, height: 100 }}
      ></Image>
      <div style={styles.textContainer}>
        {/* <div style={styles.text}>{t('Welcome to Mijobs!')}</div>
        <div style={styles.text}>
          {t('You will get 10 reward points for signing up.')}
        </div> */}
        <div style={styles.text}>{t('Congratulations! The job has been posted successfully!')}</div>
        <div style={styles.text}>
          {t('We will email you when the most suitable tradies submit their quotations.')}
        </div>
      </div>

      <Button
        onClick={handleClick}
        type="default"
        style={{
          width: 150,
          height: 40,
          borderRadius: 5,
          borderWidth: 1,
          alignSelf: 'center',
          color: '#3D77FF',
          borderColor: '#3D77FF',
        }}
      >
        {t('Close')}
      </Button>
    </div>
  )
}

const styles = {
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: '10px',
  },
  text: {
    color: '#575757',
    fontWeight: 'bold',
    fontSize: 18,
    fontFamily: "'Sans-700', Arial, Helvetica, sans-serif"
  },
}
