import React from 'react'
import { useTranslation } from 'react-i18next';
import {
  Button,
  Typography,
  Modal as AntdModal,
} from "antd";

const SignInOrRegisterModal = ({ isVisible, onCancel }) => {
  const { t } = useTranslation();
  return (
    <AntdModal
      closable={false}
      onCancel={onCancel}
      visible={isVisible}
      footer={null}
      title={null}
      width={900}
    >
      <div
        style={{
          fontWeight: "500",
          fontSize: 20,
          marginBottom: 20,
          width: "100%",
          height: 300,
          padding: "20px 20px 0 20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography.Text
          style={{
            fontWeight: "500",
            alignItems: "center",
            marginBottom: 40,
          }}
        >
          {t(
            "For posting your jobs, please Login or register your Mijobs account first."
          )}
        </Typography.Text>
        <div sytle={{ flex: 1, flexDirection: "row'" }}>
          <Button
            type="primary"
            style={{
              flex: 1,
              height: 50,
              borderRadius: 10,
              fontSize: 20,
              margin: 20,
              backgroundColor: "#3D77FF",
            }}
            onClick={() => {
              document.getElementsByClassName("wt-loginbtn")[0].click();
              this.setState({
                isModalTwoVisible: false,
                isModalVisible: false,
              });
            }}
          >
            {t("Login")}
          </Button>
          <Button
            type="primary"
            style={{
              flex: 1,
              height: 50,
              borderRadius: 10,
              fontSize: 20,
              margin: 20,
              backgroundColor: "#3D77FF",
            }}
            onClick={() => {
              window.openRegistrationForm('customer')
              this.setState({
                isModalTwoVisible: false,
                isModalVisible: false,
              });
            }}
          >
            {t("Register")}
          </Button>
        </div>
      </div>
    </AntdModal>
  );
};

export default SignInOrRegisterModal;
