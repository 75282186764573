import 'antd/dist/antd.css'
import React from 'react'
import './App.css'
import Modal from './component/modal'
import { Input, Button, ConfigProvider } from 'antd'
import Geocode from 'react-geocode'

import en from 'antd/lib/locale/en_GB'
import zhCN from 'antd/lib/locale/zh_CN'
import zhTW from 'antd/lib/locale/zh_TW'

import { withTranslation } from 'react-i18next'
import i18n from './i18n'
import SignInOrRegisterModal from './component/modal/SignInOrRegister'
import getCategoryKey from './utils/getCategoryKey'
import { saveObjToStroage } from './utils/sessionStorageHelper'
// import Button from "./component/Button"

Geocode.setApiKey('AIzaSyCvE3xp_bZk6crFjzUJ9Ajya4Z_I1PW7_4')
Geocode.setLanguage('en')
Geocode.setRegion('au')

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalVisible: false,
      isModalTwoVisible: false,
      title: '',
      category: '',
      categoryKeyPath: null,
      details: '',
      details1: '',
      details2: '',
      details3: '',
      postcode: '',
      address: '',
      date: '',
      timeOne: '',
      timeTwo: '',
      isUrgent: false,
      // States for step 3
      stepThreeState: {
        imageFileList: [],
        videoFileList: [],
        previewVisible: false,
        previewImage: '',
        previewTitle: '',
        videoUploading: false,
      },
      // The result of geocoding after selecting an address in the address dropdown
      geocodeResult: null,
      step: 0,
    }

    // Deep copy inital states
    this.initialState = this.removeModalState(this.state)
  }

  removeModalState(allState) {
    const deepCopy = JSON.parse(JSON.stringify(allState))
    delete deepCopy['isModalVisible']
    delete deepCopy['isModalTwoVisible']
    return deepCopy
  }

  submitForm() {
    console.log()
    fetch('/')
  }

  /**
   * Allow external scripts to change the language settings of this component
   * 
   * Choosing the language on mount is not possible because when the component is mounted, 
   * the variable windows.scripts_vars is not initialised yet. 
   * Getting settings from cookie is also problematic since the cookie is not updated fast enough, 
   * the component always get the previous value.
   *
   * @param {'en'|'zh'} targetLanguage 
   */
  changeLanguage(targetLanguage) {
    i18n.changeLanguage(targetLanguage, (error) => {
      if (error) {
        console.error('error occurs in changeLanguage, lang:', targetLanguage, 'error:', error);
      }
      // rerender the component
      this.setState({})
    })
  }

  componentDidMount() {
    window.app = this
    window.postJobPopup = this

    // Detect current language by language setting in cookies is not possible,
    // so we decide to change the language when the page is ready, for reason refer to 
    // the comments in changeLanguage method

    // Load previous filled data from sessionStorage
    const lastSavedStates = sessionStorage.getItem('postJobPopup')
    if (lastSavedStates) {
      const lastSavedStatesObj = JSON.parse(lastSavedStates)
      if (lastSavedStatesObj?.stepThreeState?.imageFileList) {
        // Remove the images which status are still in "uploading", not "done"
        const newImageList =
          lastSavedStatesObj.stepThreeState.imageFileList.filter(
            (uploadedItem) => uploadedItem.status === 'done'
          )
        lastSavedStatesObj.stepThreeState.imageFileList = newImageList
      }
      this.setState(lastSavedStatesObj)
    }

    const _this = this
    // Save all states when page get freshed or get left, including the modal state
    window.onbeforeunload = function () {
      const { step } = _this.state
      // When a user is not authenticated, they will go through the last step, and then login or register,
      // so that the page will usually get refreshed, save the state including the modal state
      // so that the popup can show up after they refresh
      if (step && step === 2) {
        saveObjToStroage({ ..._this.state })
      }
    }

    const { hideInput } = this.props
    const queryParams = new URLSearchParams(window.location.search)
    const showPopup = queryParams.get('showPopup')
    if (showPopup) {
      if (
        (showPopup !== 'homepage' && hideInput) ||
        (showPopup === 'homepage' && !hideInput)
      ) {
        this.setState({ isModalVisible: true })
      }
    }
  }

  /**
   * Can open the modal based on the input from the user
   * @param {{ category?: string }} params
   * Parameters:
   *  - category: A certain category of service chosen by the user
   */
  show = (params) => {
    const attributesToUpdate = {}
    if (params && params.category) {
      // Find the corresponding category key
      // Remove duplicated whitespaces
      const regex = /\s\s+/g
      const cleanedCategory = params.category.replaceAll(regex, ' ').trim()
      let cleanedParentCategory = null
      if (params?.parentCateogry) {
        cleanedParentCategory = params?.parentCategory
          .replaceAll(regex, ' ')
          .trim()
      }
      attributesToUpdate['category'] = getCategoryKey(
        cleanedCategory,
        cleanedParentCategory
      )
    }
    this.setState({ isModalVisible: true, ...attributesToUpdate })
  }

  hide = () => {
    this.setState({ isModalVisible: false })
  }

  showModal = () => {
    this.setState({ isModalVisible: true })
  }

  hideModal = () => {
    this.setState({ isModalVisible: false })
  }

  setStateOne = (callback) => {
    this.setState(callback)
  }

  setStateTwo = (callback) => {
    this.setState(callback)
  }

  setIsModalVisible = (value) => {
    this.setState({ isModalVisible: value })
  }

  setIsModalTwoVisible = (value) => {
    this.setState({ isModalTwoVisible: value })
  }

  setCategories = (categories) => {
    this.setState()
  }

  setStepThreeState = (callback) => {
    // Overwrite the step three state object
    this.setState((states) => ({
      ...states,
      stepThreeState: callback(states.stepThreeState),
    }))
  }

  setStepState = (value) => {
    if (typeof value === 'number') {
      this.setState({ step: value })
    } else {
      this.setState((states) => ({ ...states, step: value(states.step) }))
    }
  }

  resetState = () => {
    this.setState(this.initialState)
  }

  getLocale = () => {
    const { i18n } = this.props
    const lang = i18n.language
    if (lang === 'en') {
      return en
    }
    if (lang === 'zh') {
      return zhCN
    }
    if (lang === 'zh-tw' || lang === 'zh-hk') {
      return zhTW
    }
    return en
  }

  render() {
    const { t, hideInput } = this.props
    const {
      isModalVisible,
      isModalTwoVisible,
      title,
      category,
      categoryKeyPath,
      details,
      details1,
      details2,
      details3,
      postcode,
      address,
      date,
      timeOne,
      timeTwo,
      isUrgent,
      // States for step 3
      stepThreeState,
      geocodeResult,
      step,
    } = this.state

    const showInput = !hideInput

    return (
      <ConfigProvider locale={this.getLocale()}>
        {/* <ConfigProvider locale={zhCN}> */}
        {showInput && (
          <>
            <Input
              placeholder={t('e.g. I need to assemble my sofa')}
              className="jobTitle"
              bordered={false}
              value={title}
              onError={(error) => {
                console.log(error)
              }}
              onChange={(e) => {
                // console.log("state", this.state);
                this.setState({
                  title: e.target.value,
                })
              }}
            />
            <div className="form-spacer"></div>
            <Input
              placeholder={t('Postcode')}
              className="jobPostcode"
              maxLength={4}
              bordered={false}
              value={this.state.postcode}
              onChange={(e) =>
                this.setState({
                  postcode: e.target.value,
                })
              }
            />
            <Button
              onClick={() => {
                this.setState({ isModalVisible: true })
                // const isLogin = document.getElementById("checkLogin").onclick();
                // console.log("clickpost", isLogin);
                // if (!isLogin) {
                //   this.setState({ isModalTwoVisible: true });
                // } else {
                //   this.setState({ isModalVisible: true });
                // }
              }}
              className="postJobBtn"
              type="primary"
            >
              {t('Post')}
            </Button>
          </>
        )}
        <Modal
          isModalVisible={isModalVisible}
          setIsModalVisible={this.setIsModalVisible}
          setIsModalTwoVisible={this.setIsModalTwoVisible}
          {...{
            // ...this.state.stateOne,
            title,
            category,
            categoryKeyPath,
            details,
            details1,
            details2,
            details3,
            postcode,
            // ...this.state.stateTwo,
            address,
            date,
            timeOne,
            timeTwo,
            isUrgent,
            stepThreeState,
            geocodeResult,
            setStateOne: this.setStateOne,
            setStateTwo: this.setStateTwo,
            setStepThree: this.setStepThreeState,
            step,
            showInput,
          }}
          resetState={this.resetState}
          setStepState={this.setStepState}
        />
        <SignInOrRegisterModal
          isVisible={isModalTwoVisible}
          onCancel={() => this.setState({ isModalTwoVisible: false })}
        />
      </ConfigProvider>
    )
  }
}

export default withTranslation()(App)
