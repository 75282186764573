import { Button } from "antd";
import { useTranslation } from "react-i18next";

export const NextButton = ({ style, text, ...props }) => {
  const { t } = useTranslation();
  return (
    <CustomButton
      style={{
        width: "200px",
        borderRadius: "4px",
        minHeight: "50px",
        ...style,
      }}
      type="primary"
      {...props}
    >
      {t(text) || t("Next")}
    </CustomButton>
  );
};

const CustomButton = ({ style, children, ...otherProps }) => {
  return (
    <Button
      style={{
        borderRadius: 10,
        backgroundColor: "#3D77FF",
        color: "white",
        // paddingTop: '16px',
        // paddingBottom: '16px',
        ...style,
      }}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
