import { useState, useEffect, useMemo } from 'react'

const useWindowWidth = (config) => {
  const [width, setWindowWidth] = useState(0)

  const isMobile = useMemo(() => {
    return width < 500;
  }, [width])

  const updateDimensions = () => {
    const newWidth = window.innerWidth
    setWindowWidth(newWidth);
  }

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  return { width, isMobile }
}

export default useWindowWidth
