import { Upload, Modal, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";

import useWindowWidth from '../../utils/useWindowWidth';
import { NextButton } from "../Button";
import { baseURL } from "../../config";
import { styles } from ".";

import "./css/stepThree.css"

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function StepThree({
  setIsModalVisible,
  setStep,
  onSubmit,
  stepThreeState,
  setState,
  isUploading,
}) {
  const { t } = useTranslation();

  const { isMobile } = useWindowWidth();

  const {
    previewVisible,
    previewImage,
    previewTitle,
    imageFileList,
    videoFileList,
    // videoUploading,
  } = stepThreeState;

  const handleCancel = () =>
    setState((state) => ({ ...state, previewVisible: false }));

  const handleChange = ({ file, fileList }) => {
    // console.log(`fileList`, fileList)
    // console.log('file', file)
    setState((state) => ({ ...state, imageFileList: fileList }));
  };

  const handleVideoChange = ({ file, fileList, event }) => {
    // console.log('video file', file)
    // console.log(`video fileList`, fileList)
    // console.log(`video event`, event)

    if (file?.status === "uploading") {
      setState((state) => ({
        ...state,
        videoUploading: true,
        videoFileList: fileList,
      }));
    }

    if (file?.status === "done") {
      setState((state) => ({ ...state, videoFileList: fileList }));
    }

    if (file?.status === "removed") {
      setState((state) => ({ ...state, videoFileList: [] }));
    }
  };

  const handleSubmit = async () => {
    if (imageFileList.length < 1) {
      message.error(t("Please upload at least one picture"));
      return;
    }
    await onSubmit();
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setState((state) => ({
      ...state,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    }));
  };

  const uploadButton = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "#F6F6F6",
        borderWidth: 0,
      }}
    >
      <PlusOutlined size={20} color="#999999" />
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
        paddingBottom: "30px",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",
          padding: "0 40px",
        }}
      >
        <div
          style={{
            height: 80,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <div style={styles.inputText}>{t("You can choose to upload pictures or a video")}</div>
        </div>
        <div
          className="upload-section"
        >
          <div style={{ flex: 1, marginRight: 20 }}>
            <div style={inputStyles.title}>
              <span style={{ fontWeight: "bold" }}>
                {t("Pictures")}
                {"  "}
              </span>
              <span>
                ({imageFileList.length}/9){"  "}
              </span>
              <span>
                {t("Maximum 9 pictures")}
              </span>
              <span style={{ color: 'red', fontSize: '15px' }}> 
                {"*"}
              </span>
            </div>
            <div style={inputStyles.subtitle}>
              {t("Drag & drop files here or upload a file by click")}
            </div>
            <>
              <Upload
                accept="image/*"
                action={`${baseURL}/wp-admin/admin-ajax.php?action=workreap_temp_file_uploader&nonce=undefined`}
                name="file_name"
                listType="picture-card"
                fileList={imageFileList}
                onPreview={handlePreview}
                onChange={handleChange}
                itemRender={(E) => {
                  return E;
                }}
              >
                {imageFileList.length > 8 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </>
          </div>
          <div style={{ flex: 1 }}>
            <div style={inputStyles.title}>
              <span style={{ fontWeight: "bold" }}>
                {t("Video")}
                {"  "}
              </span>
              <span>
                ({videoFileList.length}/1){"  "}
              </span>
              <span>
                {t("Maximum 10MB")}
              </span>
            </div>
            <div style={inputStyles.subtitle}>
              {t("Drag & drop files here or upload a file by click")}
            </div>
            <Upload
              action={`${baseURL}/wp-admin/admin-ajax.php?action=workreap_temp_file_uploader&nonce=undefined`}
              accept=".mp4,video/*"
              name="file_name"
              fileList={videoFileList}
              listType="picture-card"
              beforeUpload={(file) => {
                const FILE_SIZE_LIMIT = 10; // Unit: MB
                const isSizeLessThanLimit = file.size / 1024 / 1024 < FILE_SIZE_LIMIT;
                if (!isSizeLessThanLimit) {
                  message.error(t("Video must smaller than 10MB!"));
                  return false;
                }
                return isSizeLessThanLimit;
              }}
              onChange={handleVideoChange}
              // previewFile={(file) => {
              //   console.log('Your upload file:', file)
              //   // Your process logic. Here we just mock to the same file
              // }}
            >
              {videoFileList.length === 0 ? uploadButton : null}
            </Upload>
          </div>
        </div>
      </div>
      <NextButton
        text="Submit"
        onClick={() => {
          // setStep((step) => step + 1)
          // setIsModalVisible(false);
          handleSubmit();
        }}
        style={{ alignSelf: "center", marginTop: isMobile ? 5 : 20 }}
        loading={isUploading}
      />
    </div>
  );
}

const inputStyles = {
  subtitle: { fontSize: 14, color: "grey", marginBottom: '5px' },
  title: { fontSize: 16, color: "#000" }
}