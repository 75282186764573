import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, Input, Menu, message, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NextButton } from '../Button'
import './css/stepOne.css'

const { SubMenu, Item } = Menu

const getSubMenuContainer = (trigger) => {
  const containerElement = document.getElementById('category-dropdown')
  // console.log('submenu aaa', containerElement)
  return containerElement
  // return trigger.parentElement;
}

const detailedMenuItems = (t) => (
  <>
    <SubMenu
      key="Handyman"
      title={t('Handyman')}
      popupClassName="full-height-menu"
    >
      {[
        'Handyman',
        'Install & Replace shelves',
        'Repair windows & doors',
        'Cleaning gutter',
        'Patched wall',
        'Hanging pictures & mirrors',
        'Assembly furniture',
        'Any another odd jobs',
      ].map(renderItem(t))}
    </SubMenu>
    <SubMenu
      key="Electricians"
      title={t('Electricians')}
      popupClassName="full-height-menu"
    >
      {[
        'Electricians',
        'New electrical installations',
        'Renovation electricians',
        'Lights installations',
        'Wires',
        'Electrical repairs',
        'Electrical power points',
        'Solar power',
        'Electrical data cabling',
      ].map(renderItem(t))}
    </SubMenu>
    <SubMenu
      key="Plumber"
      title={t('Plumber')}
      popupClassName="full-height-menu"
    >
      {[
        'Plumber',
        'New home plumbers',
        'Plumber maintenance',
        'Blocked drains',
        'Toilet repairs',
        'Bathroom installations',
        'Drains',
      ].map(renderItem(t))}
    </SubMenu>
    <SubMenu
      key="Builders"
      title={t('Builders')}
      style={styles.subMaxHeight}
      popupClassName="full-height-menu"
    >
      {[
        'Builders',
        'Architects',
        'Town planning',
        'Drafting',
        'Building design & consultants',
        'Building surveyors',
        'Building inspections',
        'Building supplies',
        'Sueveyor',
        'Structural engineer',
        'Asphalt concreting',
        'Brick layers',
        'Garages',
        'Concrete kerbs & resurfacing',
        'Excavation',
        'Plasterings',
        'Skylights',
        'Solar panel',
        'Staircase',
        'Stonemasons',
        'Verandahs',
        'Fire place',
      ].map(renderItem(t))}
    </SubMenu>
    <SubMenu
      key="Renovations"
      title={t('Renovations')}
      popupClassName="full-height-menu"
    >
      {[
        'Renovations',
        'Interior design',
        'Demolition',
        'Bathroom renovation',
        'Kitchen renovation',
        'Doors & Windows ',
        'Carpets',
        'Floors',
        { title: 'Wall paper', key: 'Wall paper' },
        'Ceilings',
        'Cladding',
        'Cabinets',
        'Curtains & blind',
        'Water proofing ',
        { title: 'Damp proofing', key: 'Damp proofing' },
        'Roof repair',
        'Security screen doors',
        'Roller doors',
      ].map(renderItem(t))}
    </SubMenu>
    <SubMenu
      key="Appliances installation & repairs"
      title={t('Appliances installation & repairs')}
      popupClassName="full-height-menu"
    >
      <Item
        key="All appliances installation & repairs"
        title={t('All appliances installation & repairs')}
      >
        {t('All appliances installation & repairs')}
      </Item>
      <Item key="Equipment hire" title={t('Equipment hire')}>
        {t('Equipment hire')}
      </Item>
    </SubMenu>
    <SubMenu
      key="Landscapes"
      title={t('Landscapes')}
      style={styles.subMaxHeight}
      popupClassName="full-height-menu"
    >
      {[
        'Landscapes',
        'Landscape architecture',
        'Garden maintenance',
        'Lawn mowing',
        'Awning suppliers',
        'Balustrades',
        'Carport',
        { title: 'Damp proofing', key: 'Damp proofing 2' },
        'Decking',
        'Fencing',
        'Fly screen',
        'Gates',
        'Gazebo',
        'Gutter',
        'Handrails',
        'Irrigation systems',
        'Patio',
        'Pergolas',
        'Shade',
        'Storage',
        'Tree fellers',
        'Render',
        'Retaining wall',
        'Water features',
        'Rainwater tanks',
      ].map(renderItem(t))}
    </SubMenu>
    <SubMenu
      key="Pest Control-menu"
      title={t('Pest Control')}
      popupClassName="full-height-menu"
    >
      <Item key="Pest Control">{t('Pest Control')}</Item>
    </SubMenu>
    <SubMenu
      key="Carpenter-menu"
      title={t('Carpenter')}
      popupClassName="full-height-menu"
    >
      <Item key="Carpenter">{t('Carpenter')}</Item>
    </SubMenu>
    <SubMenu
      key="House maintenance-menu"
      title={t('House maintenance')}
      popupClassName="full-height-menu"
    >
      <Item key="House maintenance">{t('House maintenance')}</Item>
    </SubMenu>
    <SubMenu
      key="Painting"
      title={t('Painting')}
      popupClassName="full-height-menu"
    >
      {/* <Item key="Painting">{t("Painting</")}Item> */}
      {[
        'Painting',
        'Wall & ceiling paint',
        { title: 'Wall paper', key: 'Wall paper 2' },
        'Roof paint',
      ].map(renderItem(t))}
    </SubMenu>
    <SubMenu
      key="Locksmith"
      title={t('Locksmith')}
      popupClassName="full-height-menu"
    >
      {['Locksmith', 'New lock installation', 'Replace & Repair locks'].map(
        renderItem(t)
      )}
    </SubMenu>
    <SubMenu
      key="Cleaning & Removalist"
      title={t('Cleaning & Removalist')}
      popupClassName="full-height-menu"
    >
      {[
        'Cleaning & Removalist',
        'House cleaning',
        'Airbnb property cleaning',
        'Roof cleaning',
        'Window cleaning',
        'Pressure cleaner',
        'Gutter cleaning & protection',
        'Rubbish removal',
        'Hire truck',
      ].map(renderItem(t))}
    </SubMenu>
    <SubMenu
      key="Swimming Pool"
      title={t('Swimming Pool')}
      popupClassName="full-height-menu"
    >
      {[
        'Swimming Pool',
        'Pool design',
        'Pool builder',
        'Pool fencing',
        'Pool heating',
        'Pool maintenance',
      ].map(renderItem(t))}
    </SubMenu>
    <SubMenu
      key="Concrete & tiles"
      title={t('Concrete & tiles')}
      popupClassName="full-height-menu"
    >
      {[
        'Concrete & tiles',
        'Concrete kerbs',
        'Concrete resurfacing',
        'Pavers',
        'Bathroom tiling',
        'Floor tiling',
        'Splashbacks',
      ].map(renderItem(t))}
    </SubMenu>
    <SubMenu
      key="Smart house"
      title={t('Smart house')}
      popupClassName="full-height-menu"
    >
      {[
        'Smart house',
        'Antenna services',
        'Security & camaras systom installations & repairs',
        'Smart home systoms installations',
      ].map(renderItem(t))}
    </SubMenu>
  </>
)

const renderItem = (t) => (item) => {
  if (typeof item === 'string') {
    return (
      <Item key={item} title={t(item)}>
        {t(item)}
      </Item>
    )
  }
  if (typeof item === 'object') {
    // let title = item.title
    let key = item.key
    return (
      <Item key={key} title={t(key)}>
        {t(key)}
      </Item>
    )
  }
}

/* const menuItems = (t) => {
  const menuTitles = [
    'Handyman',
    'Electricians',
    'Plumber',
    'Builder',
    'Renovations',
    'Appliances installation & repairs',
    'Landscapes',
    'Pest control',
    'Painting',
    'Locksmith',
    'Cleaning & Removalist',
    'Swimming Pool',
    'Concrete & tiles',
    'Smart house',
  ]

  return (
    <>
      {menuTitles.map((item) => {
        return (
          <Item key={item} title={t(item)}>
            {t(item)}
          </Item>
        )
      })}
    </>
  )
} */

export default function StepOne({ setStep, setState, title, category }) {
  const { t } = useTranslation()
  const menu = (
    <Menu
      onClick={(e) => {
        console.log(e)
        setState((state) => ({
          ...state,
          category: e.key,
          categoryKeyPath: e.keyPath,
        }))
      }}
      // defaultSelectedKeys="Cleaning"
      selectedKeys={category ? category : null}
      getPopupContainer={getSubMenuContainer}
    >
      {detailedMenuItems(t)}
    </Menu>
  )

  const validateInput = () => {
    // make sure the title is not empty
    const titleNotEmpty = typeof title === 'string' && title.trim().length > 0
    // const detailsNotEmpty =
    //   typeof details1 === 'string' && details1.trim().length > 0
    // const details2NotEmpty =
    //   typeof details2 === 'string' && details2.trim().length > 0
    // this field is optional
    // const details3NotEmpty =
    //   typeof details3 === 'string' && details3.trim().length > 0
    let errors = []
    if (!titleNotEmpty) {
      errors.push(t('Please enter a job title'))
    }
    // if (!detailsNotEmpty) {
    //   errors.push(t('Please enter the details of the job'))
    // }
    // if (!details2NotEmpty) {
    //   errors.push(t('Please enter the dimensions details of the job'))
    // }
    // if (!details3NotEmpty) {
    //   errors.push(t('Please enter job details'))
    // }
    return {
      isValid: titleNotEmpty,
      errors: errors.length > 0 ? errors : null,
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        width: '80%',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
      }}
    >
      <div>
        <div style={styles.textContainer}>
          <Typography.Text style={styles.text}>
            {t('What do you want to be done? (Job Title)')}
          </Typography.Text>
          <Typography.Text style={{ color: 'red', fontSize: '15px' }}>
            {'*'}
          </Typography.Text>
        </div>
        <Input.TextArea
          rows={2}
          autoSize={false}
          bordered={false}
          placeholder={t(
            'e.g. I want someone to help me to assemble furniture'
          )}
          style={{ resize: 'none', backgroundColor: grey }}
          value={title}
          onChange={(e) =>
            setState((state) => ({ ...state, title: e.target.value }))
          }
        />
      </div>
      <div>
        <div style={styles.textContainer}>
          <Typography.Text style={styles.text}>
            {t('Which category does it belong to?')}{' '}
            <Typography.Text style={{ color: 'black', fontWeight: 'bold' }}>
              {t('optional')}
            </Typography.Text>
          </Typography.Text>
        </div>
        <Dropdown
          overlay={menu}
          overlayStyle={styles.maxHeight}
          trigger="click"
          getPopupContainer={(trigger) => {
            // Use the parent element (the modal) as the container
            // so that the Dropdown menu will not scroll with the page
            // console.log('parent', trigger.parentElement)
            return trigger.parentElement
          }}
        >
          <Button
            id="category-dropdown"
            style={{
              width: 200,
              backgroundColor: grey,
              borderWidth: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            bordered="false"
          >
            {t(category) || ''} <DownOutlined />
          </Button>
        </Dropdown>
      </div>
      <NextButton
        onClick={() => {
          const { isValid, errors } = validateInput()
          if (errors) {
            message.error(errors[0])
          }
          if (isValid) {
            setStep(1)
          }
        }}
        style={{ alignSelf: 'center', marginTop: 10 }}
      />
    </div>
  )
}

const grey = '#F7F7F7'

const styles = {
  textContainer: { marginBottom: 10 },
  text: { color: '#000', fontSize: '18px' },
  maxHeight: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    maxHeight: '100vh',
    position: 'fixed',
  },
  subMaxHeight: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    maxHeight: '100vh',
  },
}

const windowSize = () => {
  styles.maxHeight = {
    ...styles.maxHeight,
    overflowY: 'scroll',
    maxHeight: '100vh',
  }
  styles.subMaxHeight = {
    ...styles.subMaxHeight,
    overflowY: 'scroll',
    maxHeight: '100vh',
  }
}

window.addEventListener('resize', windowSize)
