import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

let elementToMount = document.getElementById('root')
if (elementToMount) {
  ReactDOM.render(
    <App />,
    elementToMount
  );
}

let elementToMountModalOnly = document.getElementById('post-job-popup')
if (elementToMountModalOnly) {
  ReactDOM.render(
    <App hideInput={true} />,
    elementToMountModalOnly
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
