import { v4 } from "uuid"

/**
 * A helper function to get session token that generates session token for Google map API calls,
 * it will create a session ID and store into session storage, or read from session storage if it is already generated
 */
const getSessionToken = (storageKey = 'googleMapSessionId') => {
  let sessionId = sessionStorage.getItem(storageKey)
  
  if (sessionId) {
    return sessionId
  } else {
    // Create new and write into session storage
    const newSessionId = v4();
    sessionStorage.setItem(storageKey, newSessionId);
    sessionId = newSessionId
  }
  
  return sessionId;
}

export default getSessionToken
