import { Input, message, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import generateDetails from '../../utils/generateDescription'
import { NextButton } from '../Button'
import './css/stepOne.css'

export default function StepJobDetail({
  setStep,
  setState,
  details1,
  details2,
  details3,
}) {
  const { t } = useTranslation()

  const questions = [
    t('1) Do you have materials?'),
    t('2) What are dimensions? (e.g. height, width, depth)'),
    t('3) Additional descriptions'),
  ]

  const validateInput = () => {
    // make sure the first two detail fields are not empty
    const detailsNotEmpty =
      typeof details1 === 'string' && details1.trim().length > 0
    const details2NotEmpty =
      typeof details2 === 'string' && details2.trim().length > 0
    // Assume this field is optional
    // const details3NotEmpty =
    //   typeof details3 === 'string' && details3.trim().length > 0
    let errors = []
    if (!detailsNotEmpty) {
      errors.push(t('Please enter the details of the job'))
    }
    if (!details2NotEmpty) {
      errors.push(t('Please enter the dimensions details of the job'))
    }
    // if (!details3NotEmpty) {
    //   errors.push(t('Please enter job details'))
    // }
    return {
      isValid: detailsNotEmpty && details2NotEmpty,
      errors: errors.length > 0 ? errors : null,
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        width: '80%',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
      }}
    >
      <div>
        <div style={styles.textContainer}>
          <Typography.Text style={styles.text}>
            {t('1) Do you have materials?')}
          </Typography.Text>
          <Typography.Text style={{ color: 'red', fontSize: '15px' }}>
            {'*'} <br />
          </Typography.Text>
        </div>
        <Input.TextArea
          rows={2}
          autoSize={false}
          bordered={false}
          placeholder={t('detailDescriptions1')}
          style={{ backgroundColor: grey }}
          value={details1}
          onChange={(e) =>
            setState((state) => ({
              ...state,
              details1: e.target.value,
              details: generateDetails(
                [e.target.value, details2, details3],
                questions
              ),
            }))
          }
        />
      </div>
      <div>
        <div style={styles.textContainer}>
          <Typography.Text style={styles.text}>
            {t('2) What are dimensions? (e.g. height, width, depth)')}
          </Typography.Text>
          <Typography.Text style={{ color: 'red', fontSize: '15px' }}>
            {'*'} <br />
          </Typography.Text>
        </div>
        <Input.TextArea
          rows={2}
          autoSize={false}
          bordered={false}
          placeholder={t('detailDescriptions2')}
          style={{ backgroundColor: grey }}
          value={details2}
          onChange={(e) =>
            setState((state) => ({
              ...state,
              details2: e.target.value,
              details: generateDetails(
                [details1, e.target.value, details3],
                questions
              ),
            }))
          }
        />
      </div>
      <div>
        <div style={styles.textContainer}>
          <Typography.Text style={styles.text}>
            {t('3) Additional descriptions')}
          </Typography.Text>
        </div>
        <Input.TextArea
          rows={2}
          autoSize={false}
          bordered={false}
          placeholder={t('detailDescriptions')}
          style={{ backgroundColor: grey }}
          value={details3}
          onChange={(e) =>
            setState((state) => ({
              ...state,
              details3: e.target.value,
              details: generateDetails(
                [details1, details2, e.target.value],
                questions
              ),
            }))
          }
        />
      </div>
      <NextButton
        onClick={() => {
          const { isValid, errors } = validateInput()
          if (errors) {
            message.error(errors[0])
          }
          if (isValid) {
            setStep(2)
          }
        }}
        style={{ alignSelf: 'center', marginTop: 10 }}
      />
    </div>
  )
}

const grey = '#F7F7F7'

const styles = {
  textContainer: { marginBottom: 10 },
  text: { color: '#000', fontSize: '18px' },
  maxHeight: { overflowX: 'hidden', overflowY: 'scroll', maxHeight: '100vh' },
  subMaxHeight: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    maxHeight: '100vh',
  },
}

const windowSize = () => {
  styles.maxHeight = {
    ...styles.maxHeight,
    overflowY: 'scroll',
    maxHeight: '100vh',
  }
  styles.subMaxHeight = {
    ...styles.subMaxHeight,
    overflowY: 'scroll',
    maxHeight: '100vh',
  }
}

window.addEventListener('resize', windowSize)
