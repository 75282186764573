import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from './locales/en';
import translationCN from './locales/zh';
import translationCNTW from './locales/zhTw';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  // Simplified Chinese
  zh: {
    translation: translationCN
  },
  // Traditional Chinese
  "zh-tw": {
    translation: translationCNTW
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;