import {
  Checkbox,
  DatePicker,
  Input,
  // Menu,
  message,
  Typography,
} from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { NextButton } from '../Button'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import { googleApikey } from '../../config'
import getSessionToken from '../../utils/getSessionToken'

const sessionToken = getSessionToken()

export default function StepTwo({
  setStep,
  setState,
  postcode,
  address,
  date,
  isUrgent,
  timeOne,
  timeTwo,
  geocodeResult
}) {
  const { t } = useTranslation()
  const [autoAddress, setAutoAddress] = useState(geocodeResult)
  /* const menuOne = (
    <Menu
      onClick={(e) => setState((state) => ({ ...state, timeOne: e.key }))}
      defaultSelectedKeys="AM"
    >
      <Menu.Item key="AM">{t('AM')}</Menu.Item>
      <Menu.Item key="PM">{t('PM')}</Menu.Item>
    </Menu>
  )
  const menuTwo = (
    <Menu
      onClick={(e) => setState((state) => ({ ...state, timeTwo: e.key }))}
      defaultSelectedKeys="1"
    >
      {Array(12)
        .fill(0)
        .map((_, index) => (
          <Menu.Item key={`${index + 1}`}>{index + 1}</Menu.Item>
        ))}
    </Menu>
  ) */
  const validateInput = () => {
    // make sure the title is not empty
    const postcodeNotEmpty = typeof postcode === 'string' && postcode.trim().length > 0
    const addressNotEmpty = typeof address === 'string' && address.trim().length > 0
    // const dateNotEmpty = typeof date === 'string' && date.trim().length > 0
    // const timeOneNotEmpty = typeof timeOne === 'string' && timeOne.trim().length > 0
    // const timeTwoNotEmpty = typeof timeTwo === 'string' && timeTwo.trim().length > 0
    const postcodeIsValid = /\d\d\d\d/.test(postcode.trim())

    let errors = []
    if (!postcodeNotEmpty) {
      errors.push(t('Please enter a postcode'))
    }
    if (!addressNotEmpty) {
      errors.push(t('Please enter job address'))
    }
    // if (!dateNotEmpty) {
      
    //   errors.push(t('Please enter job date'))
    // }
    // if (!timeOneNotEmpty) {
    //   errors.push(t('Please enter job timeline'))
    // }
    // if (!timeTwoNotEmpty) {
    //   errors.push(t('Please enter job timeline'))
    // }
    if (!postcodeIsValid) {
      errors.push(t('Invalid postcode format'))
    }
    return {
      // isValid: postcodeNotEmpty && addressNotEmpty && dateNotEmpty && timeOneNotEmpty && timeTwoNotEmpty && postcodeIsValid,
      isValid: postcodeNotEmpty && addressNotEmpty && postcodeIsValid,
      errors: errors.length > 0 ? errors : null,
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        width: '80%',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
      }}
    >
      <div>
        <div style={styles.textContainer}>
          <Typography.Text style={styles.text}>
            {t('Where do you need this job to be done? (Job Location)')}
          </Typography.Text>
          <Typography.Text style={{ color: "red", fontSize: '15px' }}>
            {'*'}
          </Typography.Text>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Input
            placeholder={t('Postcode')}
            style={{
              flex: 1,
              marginRight: 10,
              backgroundColor: grey,
              height: 38,
            }}
            bordered={false}
            maxLength={4}
            value={postcode}
            onChange={(e) =>
              setState((state) => ({ ...state, postcode: e.target.value }))
            }
          />
          <GooglePlacesAutocomplete
            apiKey={googleApikey}
            apiOptions={{
              region: 'au',
              language: 'en-AU'
            }}
            withSessionToken={sessionToken}
            autocompletionRequest={{
              componentRestrictions: {
                country: ['au']
              }
            }}
            selectProps={{
              placeholder: t('Address'),
              components: {
                DropdownIndicator: null,
                IndicatorSeparator: null,
              },
              styles: {
                container: (provided) => ({
                  ...provided,
                  flex: 2,
                  backgroundColor: grey,
                  borderWidth: '0px',
                  borderRadius: '2px',
                }),
                input: (provided) => ({
                  ...provided,
                  height: 18,
                  borderWidth: '0px',
                  zIndex: 2,
                }),
                option: (provided) => ({
                  ...provided,
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  position: 'unset',
                }),
                control: (provided) => ({
                  ...provided,
                  backgroundColor: grey,
                  borderWidth: '0px',
                }),
              },
              value: autoAddress,
              onChange: (address) => {
                // console.log(address)
                geocodeByPlaceId(address.value.place_id).then(results => {
                  // console.log('geocodeByPlaceIdResults:', results);
                  const [place] = results;
                  const postalCodeComponent = place.address_components.find(c => c.types.includes('postal_code')) || {};
                  const { long_name: postalCode = '' } = postalCodeComponent
                  // set postcode
                  // console.log("postalCode",postalCode);
                  setState((state) => ({ ...state, postcode: postalCode }))
                })
                setAutoAddress(address)
                setState((state) => ({
                  ...state,
                  address: address.label,
                  geocodeResult: address,
                }))
              },
            }}
          />
        </div>
      </div>
      <div>
        <div style={styles.textContainer}>
          <Typography.Text style={styles.text}>
            {/* {t('When do you need it done? (Optional)')} */}
            {t('When do you need it done?')}
          </Typography.Text>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <DatePicker
            format="YYYY-MM-DD"
            style={{
              marginRight: 10,
              backgroundColor: grey,
              borderWidth: 0,
              color: 'black',
            }}
            disabledDate={(current) => {
              // Disable all days before today
              return current < moment().startOf('day')
            }}
            onChange={(date, dateString) => {
              console.log(date, dateString)
              setState((state) => ({ ...state, date: dateString }))
            }}
            value={!!date ? moment(date) : null}
          />
          <div
            style={{
              flex: 3,
              marginLeft: 10,
            }}
          ></div>
        </div>
      </div>
      <div>
        <div style={{ marginTop: '12px' }}>
          <Checkbox
            onChange={(e) => {
              setState((state) => ({
                ...state,
                isUrgent: e.target.checked,
              }))
            }}
            checked={isUrgent}
          >
            {t('It is urgent')}
          </Checkbox>
        </div>
      </div>
      <NextButton onClick={() => {
        const { isValid, errors } = validateInput()
        if (errors) {
          message.error(errors[0])
        }
        if (isValid) {
          setStep(3)
        }
      }} style={{ alignSelf: 'center' }} />
    </div>
  )
}

const grey = '#F7F7F7'

const styles = {
  textContainer: { marginBottom: 10 },
  text: { color: '#000', fontSize: '18px' },
}
